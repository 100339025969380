<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  watch: {},
  methods: {},
  components: {},
};
</script>
<style lang='scss' scoped>
.small-plane {
  background: #fff;
  // width: 1700px;
}
</style>